@import 'colors';
@import 'media';

.u-type-label {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 4px;
  display: flex;
  align-items: center;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  svg {
    height: 12px;
    width: 12px;
  }

  &--left {
    right: auto;
    left: 0;
    justify-content: flex-end;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &--md {
    padding: 0 8px;
    height: 40px;
    width: 62px;

    &.u-type-label--left {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    svg {
      height: 26px;
      width: 26px;
    }
  }

  &--rounded {
    height: 24px;
    width: 24px;
    border-radius: 50%;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &--sponsored {
    background-color: #bef2e5;
  }

  &--tuition-fee {
    background-color: #c5e8f1;
  }

  &--money-back {
    background-color: #ffe2db;

    &.u-type-label--rounded svg {
      padding-left: 2px;
    }
  }
}
