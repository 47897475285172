@import 'colors';

.topic-list {
  &__topic {
    border-bottom: 1px dashed #c2c8d2;

    h4 {
      position: relative;
      margin-bottom: 0;
      padding: 9px 30px 13px 20px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease-in-out;

      @include mobile {
        padding-left: 0;
      }

      .hours {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #c2c8d2;
        white-space: nowrap;

        @include mobile {
          display: none;
        }

        &--small {
          display: none;
          padding-left: 0;

          @include mobile {
            display: block;
          }
        }
      }

      svg {
        position: absolute;
        top: 20px;
        right: 0;
        height: 8px;
        width: 14px;
        transition: all 0.2s ease-in-out;

        @include mobile {
          top: 40px;
        }

        path {
          fill: #c2c8d2;
        }
      }
    }

    &__description {
      padding-left: 20px;
      max-height: 0;
      font-size: 14px;
      font-weight: 400;
      color: $base;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;

      @include mobile {
        padding-left: 0;
      }

      p {
        margin-top: 6px;
        margin-bottom: 0;
        padding-right: 20px;

        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }

    &--inactive h4 {
      cursor: auto;
    }

    &--expanded {
      h4 {
        color: $primary;

        svg {
          transform: rotate(180deg);
        }
      }

      .topic-list__topic {
        &__description {
          max-height: 800px;
          opacity: 1;
        }
      }
    }
  }
}
