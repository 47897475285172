@import 'colors';
@import 'media';

.course-calendar {
  width: 100%;
  min-height: 164px;

  &--small {
    position: relative;
    display: none;

    @include tablet {
      display: block;
    }

    @include mobile {
      display: block;
    }

    .loading {
      background-color: $light-white;
      align-items: flex-start;
      padding-top: 160px;

      .loader:after {
        background: $light-white;
      }
    }

    .course-filters__button {
      @include tablet {
        position: absolute;
        z-index: 9;
        right: 0;
        top: 10px;
      }

      @include mobile {
        margin: 0 auto;
      }
    }

    .course-filters {
      &__filters {
        width: 100%;
      }

      .custom-select {
        width: calc(100% / 3 - 10px);
        margin: 0 5px;
      }
    }
  }

  header {
    margin-bottom: 20px;
    display: flex;

    .dates-container {
      width: 75%;
      max-width: 806px;
    }

    .course-calendar__course-col {
      padding: 0;
      font-size: 12px;
      font-weight: 700;
      color: #c2c8d2;
      display: flex;
      align-items: flex-end;
    }

    .course-calendar__day-col {
      border: none !important;
    }
  }

  &__content {
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;

    .course-calendar__row {
      flex: 1;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out, visibility 0s linear;
    }

    &--loading {
      .course-calendar__row {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.1s ease-in-out, visibility 0s linear 0.1s;
      }
    }

    .loading {
      padding-left: 370px;
    }
  }

  &__month-switch {
    width: calc(100% - 500px);
    min-width: 300px;
    display: flex;

    @media (min-width: $desktop-width - 1px) and (max-width: 1200px) {
      min-width: 240px;
      align-items: flex-end;
    }

    button {
      margin-top: 8px;
    }

    .month-year {
      padding: 0 10px;
      flex: 1;
      font-size: 28px;
      font-weight: 700;
      color: $primary;
      text-align: center;

      @media (min-width: $desktop-width - 1px) and (max-width: 1200px) {
        font-size: 22px;
      }

      span {
        font-weight: 300;
        margin-left: 10px;
      }
    }
  }

  &__cta {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__row {
    position: relative;
    display: flex;

    &--past {
      a {
        color: #c2c8d2;
      }

      .u-type-label {
        display: none !important;
      }

      .course-calendar__booked-day {
        background-color: #c2c8d2;
      }
    }

    .course-calendar__day-col:first-of-type {
      border-left: 1px solid #ececec;
    }
  }

  &__course-col {
    position: relative;
    padding: 14px 20px 14px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: $dark;
    width: calc(100% - 806px);
    min-width: 25%;

    a {
      display: flex;
      flex-direction: row;

      .u-type-label {
        position: relative;
        top: -1px;
        margin-top: 0;
        margin-right: 10px;
        display: inline-flex;
      }

      strong {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: all 0.2s ease-in-out;
      }

      span {
        width: 100%;
        display: block;
        font-size: 12px;
        color: $grey;
      }

      &:hover {
        color: $primary;
      }
    }

    &.empty {
      font-weight: 300;
      color: $base;
    }
  }

  &__day-col {
    position: relative;

    border-right: 1px solid #ececec;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    color: $base;
    text-align: center;

    &.first {
      border-left: 1px solid #ececec;
    }
  }

  &__booked-day {
    position: absolute;
    z-index: 9;
    top: 50%;
    width: calc(100% + 2px);
    height: 10px;
    background-color: $primary;
    transform: translate(-1px, -50%);
    cursor: pointer;

    .tooltip {
      position: absolute;
      z-index: 9;
      top: 20px;
      left: 50%;
      margin-right: -50%;
      padding: 10px 10px 10px;
      width: 260px;
      background-color: $white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      text-align: left;
      opacity: 0;
      transform: translate(-50%, 0) scale(0);
      transition: opacity 0.3s ease-in-out 0.1s, transform 0.2s ease-in-out;

      p {
        margin-bottom: 0;

        span {
          display: block;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    &--active {
      z-index: 99;

      .tooltip {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
      }
    }
  }
}
