@import 'colors';

.instructors {
  padding: 30px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;

  @include tablet {
    padding: 20px;
  }

  @include mobile {
    padding: 20px;
  }

  h4 {
    margin-bottom: 20px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 50px;
  }

  &__profile {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-bottom: 0;
    }

    .header {
      display: flex;
      align-items: center;
    }

    .image {
      margin-right: 20px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $base;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      color: $dark;

      div {
        display: flex;
      }

      .degree {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: $base;
      }
    }

    .linkedin {
      margin-left: 6px;
      padding: 3px 4px 6px;
      align-self: flex-start;
      height: 24px;
      width: 24px;
      display: block;
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;

      svg {
        // background-color: $dark;
        height: 16px;
        width: 16px;
      }
    }
  }
}
