@import 'colors';
@import 'media';
@import 'components/course-suggestions/CourseSuggestions.scss';

.landing {
  overflow: hidden;

  &__portrait {
    padding-top: 50px;
    min-height: 640px;
    max-width: 640px;
    transform: translateY(32px);
    opacity: 0;

    @include tablet {
      padding-top: 0;
      padding-right: 80px;
      width: 100%;
      transform: translateY(25px);
    }

    @include mobile {
      padding-top: 0;
      margin: 0 auto;
      max-width: 400px;
      transform: translateY(19px);
    }

    img {
      width: 100%;

      @include mobile {
        margin: 0 auto;
        max-width: 340px;
        width: 90%;
      }
    }

    &--loaded {
      min-height: auto;
      opacity: 1;
      transition: opacity 0.4s ease-in 0.2s;
    }

    &--0 img {
      transform: translateY(-1px);
    }

    &--1 img {
      transform: translateY(-10px);
    }

    &--2 img {
      transform: translateY(1px);
    }
  }

  main {
    border-bottom: 1px solid #e8e8e8;

    h1 {
      font-size: 52px;

      @include mobile {
        font-size: 36px;
      }
    }

    p {
      color: $dark;
    }

    .block-intro {
      padding-left: 20%;

      @include tablet {
        padding-left: 80px;
        padding-bottom: 60px;
        transform: translateY(40px);
      }

      @include mobile {
        padding-left: 0;
      }
    }

    .u-pattern {
      top: auto;
      bottom: 0;
      width: 30%;
      height: 400px;
      background-size: contain;
      background-position: -40px bottom;
      background-image: url('/static/assets/patterns/landing-corner.svg');

      @include tablet {
        width: 40%;
      }

      @include mobile {
        width: 100%;
        height: 80%;
      }
    }

    .u-col--left {
      position: relative;
      margin-top: 120px;
      padding-bottom: 120px;

      @include tablet {
        margin-top: 60px;
        padding-bottom: 0;
        max-width: 80%;
        align-self: flex-start;
      }

      @include mobile {
        margin-top: 70px;
        padding-bottom: 40px;
      }
    }

    .u-col--right {
      min-height: 540px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      @include tablet {
        width: 70%;
        min-height: 400px;
        align-self: flex-end;
      }

      @include mobile {
        height: 280px;
        min-height: auto;
      }

      .u-container {
        padding-left: 0;
        display: flex;
        align-items: flex-end;
      }

      .u-bg {
        height: calc(100% - 40px);
        right: 0;
        left: auto;
        width: calc(100% * 2 * 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        .u-pattern {
          height: 90%;
          width: 90%;
          background-position: right top;
          background-image: url('/static/assets/landing/dot-pattern.svg');
        }
      }
    }
  }

  .suggestions {
    margin-top: 120px;
  }

  .topics {
    margin-top: 140px;

    @include mobile {
      margin-top: 100px;
    }

    .block-intro {
      @include tablet {
        align-items: center;
        text-align: center;

        &__title {
          display: flex;
          justify-content: center;
        }
      }

      @include mobile {
        align-items: center;
        text-align: center;

        &__title {
          display: flex;
          justify-content: center;
        }
      }
    }

    .u-pattern {
      background-size: contain;
      background-image: url('/static/assets/patterns/topics.svg');
    }

    .u-col--left {
      width: 60%;

      @include tablet {
        width: 100%;
        order: 2;
      }

      @include mobile {
        width: 100%;
        order: 2;
      }
    }

    .u-col--right {
      width: 40%;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }

      .u-container {
        display: flex;
        align-items: center;
      }
    }

    &__list {
      position: relative;
      margin: 0 -10px;
      padding: 30px 0;
      display: flex;
      width: calc(100% + 20px);
      height: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
    }

    &__category-link {
      position: relative;
      margin: 20px 10px;
      display: flex;
      align-items: flex-end;
      background-color: $white;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);

      @include mobile {
        width: 100% !important;
        height: 240px !important;
      }

      span {
        padding: 8px 16px;
        width: 100%;
        display: block;
        background-color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: $dark;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        span {
          color: $primary;
        }
      }

      &--qa {
        width: calc(40% - 20px);
        height: 290px;
        background-image: url('/static/assets/landing/qa.png');
      }

      &--dev {
        width: calc(60% - 20px);
        height: 200px;
        background-image: url('/static/assets/landing/dev.png');
      }

      &--management {
        width: calc(40% - 20px);
        height: 240px;
        background-image: url('/static/assets/landing/management.png');
      }

      &--all {
        padding: 20px;
        width: 180px;
        height: 240px;
        background-color: $primary;
        color: $white;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: $white;
          color: $primary;
        }

        @include mobile {
          height: 60px !important;
        }
      }
    }
  }

  .why {
    margin-top: 200px;

    @include tablet {
      margin-top: 140px;
    }

    @include mobile {
      margin-top: 100px;
    }

    &__list {
      margin: 0 -25px;
      display: flex;
      flex-wrap: wrap;
    }

    .grid-item {
      width: calc(100% / 4 - 50px);
      margin: 50px 25px;

      @include mobile {
        width: calc(100% - 50px);
        margin: 10px 25px;
      }

      &__icon {
        margin-bottom: 10px;
      }
    }
  }

  .types {
    margin-top: 150px;

    @include tablet {
      margin-top: 100px;
    }

    @include mobile {
      margin-top: 80px;
    }

    .u-container {
      display: flex;

      @include mobile {
        flex-direction: column-reverse;
      }
    }

    .u-col--left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include mobile {
        align-items: stretch;
      }
    }

    p,
    li {
      font-size: 18px;

      @include mobile {
        font-size: 16px;
      }
    }

    ul {
      margin-bottom: 40px;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;

      .u-type-icon {
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
        border-radius: 50%;

        svg {
          height: 22px;
          width: 22px;
        }
      }
    }

    .block-intro {
      @include mobile {
        margin-top: 40px;
        text-align: left;
        align-items: flex-start;

        &__title {
          justify-content: flex-start;
        }
      }
    }

    &__visual {
      position: relative;
      padding-top: 40px;
      display: flex;
      justify-content: center;

      img {
        position: relative;
        z-index: 9;
        width: 80%;
        max-width: 380px;
        border-radius: 5px;
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);
      }

      .u-pattern {
        width: 70%;
        right: 0;
        left: auto;
        background-position: right top;
        transform: translateY(-40px);
        background-image: url('/static/assets/patterns/sponsored.svg');
        background-size: contain;

        @include mobile {
          transform: translateY(-20px);
        }
      }
    }
  }

  .partners {
    margin-top: 160px;

    @include tablet {
      margin-top: 100px;
    }

    @include mobile {
      margin-top: 100px;
    }

    .block-intro {
      margin-bottom: 70px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__titles {
      margin-bottom: 0 !important;

      h3 {
        font-size: 28px;
      }
    }

    &__companies,
    &__universities {
      margin-bottom: 60px;
    }

    &__logos {
      max-width: 850px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 80px;
      row-gap: 21px;

      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mobile {
        padding: 0 30px;
        grid-template-columns: repeat(1, 1fr);
      }

      &--item {
        position: relative;
        margin-bottom: 0;
        height: 80px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .testimonials {
    position: relative;
    margin-top: 100px;

    &__title {
      padding: 2rem 0;
    }

    @include mobile {
      margin-top: 100px;
    }
  }

  .cta {
    padding: 150px 0;

    @include mobile {
      padding: 115px 0;
    }
  }
}
