@import 'colors';
@import 'media';

.my-courses {
  position: relative;
  padding-top: 230px;
  padding-bottom: 200px;

  @include mobile {
    padding-top: 150px;
  }

  h1 {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 10px solid $primary;
  }

  .u-pattern {
    background-size: contain;
    max-width: 540px;
    width: 40%;

    &.left {
      background-position: left top;
      background-image: url('/static/assets/patterns/my-list-left.svg');

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    &.right {
      left: auto;
      right: 0;
      background-position: right top;
      background-image: url('/static/assets/patterns/my-list-right.svg');

      @include tablet {
        width: 70%;
      }

      @include mobile {
        width: 90%;
        max-width: 340px;
      }
    }
  }

  .course-table {
    &__row {
      margin-bottom: 13px;
      padding: 20px;
      border: 1px solid $light-grey;
      display: flex;
      align-items: center;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 600;
      color: $dark;
      transition: all 0.2s ease-in-out;

      @include mobile {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &--active:hover {
        border-color: $dark;

        .course-table__col--name {
          color: $primary;
        }
      }

      &--header {
        font-size: 18px;
        text-transform: uppercase;
        border: none;

        @include mobile {
          display: none;
        }
      }
    }

    &__col {
      padding-left: 60px;
      min-width: 190px;
      width: 17%;
      transition: all 0.2s ease-in-out;

      @include tablet {
        padding-left: 20px;
        min-width: auto;
      }

      @include mobile {
        padding-left: 0;
        min-width: 100%;
        width: 100%;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &--grade {
        @include mobile {
          margin-top: 20px;
          order: 3;
        }
      }

      &--dates {
        width: 140px;
        min-width: auto;

        @include mobile {
          order: 1;
        }

        span {
          padding: 6px;
          border: 1px solid $dark;
          display: flex;
          justify-content: center;
          border-radius: 30px;
        }
      }

      &--status {
        @include mobile {
          order: 4;
        }

        span {
          padding: 6px 10px;
          display: flex;
          justify-content: center;
          white-space: nowrap;
          border-radius: 30px;
          text-align: center;
        }

        &.enrolled span {
          background-color: $primary;
        }

        &.happening span {
          background-color: $blue-gradient-top;
          color: $white;
        }

        &.passed span {
          background-color: $success;
        }

        &.failed span {
          background-color: $red;
          color: $white;
        }
      }

      &--role {
        @include mobile {
          order: 2;
          width: calc(100% - 160px);
          min-width: auto;
          text-align: right;
          font-weight: 500;
        }
      }

      &--name {
        flex: 1;

        @include mobile {
          margin: 10px 0 20px;
          width: 100%;
          order: 4;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }

  &__empty {
    margin: 0 auto;
    padding-top: 60px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include mobile {
      padding-top: 20px;
    }

    p {
      font-size: 18px;
    }

    .link {
      &--custom {
        position: relative;
        padding: 2px;
        display: block;
        color: $dark;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: 6px;
          height: 6px;
          width: 100%;
          background-color: $primary;
          transition: height 0.1s ease-in-out;
        }
      }

      &:hover::after {
        height: calc(100% - 12px);
      }
    }
  }
}
