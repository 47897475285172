$desktop-hd-width: 1280px;
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile: 480px;
$mobile-min: 320px;

/* 
  # Device = Desktops
  # Screen = 1280px to higher resolution desktops
*/
@mixin desktop-hd {
  @media (min-width: $desktop-hd-width) {
    @content;
  }
}

/* 
  # Device = Laptops, Desktops
  # Screen = B/w 1024px to 1279px
*/
@mixin desktop {
  @media (min-width: $desktop-width) and (max-width: $desktop-hd-width - 1px) {
    @content;
  }
}

/* 
  # Device = Tablets, Ipads (portrait)
  # Screen = B/w 768px to 1023px
*/
@mixin tablet {
  @media (min-width: $tablet-width) and (max-width: $desktop-width - 1px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $tablet-width - 1px) {
    @content;
  }
}
