@import 'colors';
@import 'media';

.cookie-banner {
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 40px;

  @include mobile {
    bottom: 20px;
  }

  p {
    margin-bottom: 0;
    color: $dark;
    font-size: 16px;

    @include mobile {
      font-size: 14px;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    border: none;
    background: none;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  &__box {
    position: relative;
    margin: 0 auto;
    padding: 24px 30px;
    max-width: 770px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);

    @include mobile {
      padding: 20px 20px 28px;
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    @include mobile {
      flex-wrap: wrap;
    }

    p {
      padding: 0 20px;
      flex: 1;
    }

    svg {
      width: 40px;
      height: 40px;
    }

    @include mobile {
      padding-right: 0;
    }
  }

  &__cta {
    display: flex;
    align-items: center;

    @include mobile {
      margin-top: 20px;
      width: 100%;
      justify-content: center;
    }

    .button {
      margin: 0 10px;
      border-width: 1px;
      box-shadow: none;
      white-space: nowrap;
      min-width: 100px;
      padding: 6px 10px;
    }
  }
}
