@import 'media';
@import 'colors';

.learning-paths {
  background-color: $light-white;

  main {
    position: relative;
    padding-top: 90px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;

    @include tablet {
      box-shadow: none;
      background-color: $light-white;
    }

    @include mobile {
      box-shadow: none;
      background-color: $light-white;
    }

    .u-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .block-intro {
      max-width: 580px;
      margin-bottom: 85px;

      @include tablet {
        margin-bottom: 0;
      }

      @include mobile {
        margin-bottom: 0;
      }
    }

    .u-pattern {
      background-size: contain;

      &.left {
        background-position: left top;
        background-image: url('/static/assets/patterns/learning-paths-left.svg');
      }

      &.right {
        background-position: right top;
        background-image: url('/static/assets/patterns/learning-paths-right.svg');
      }
    }

    .filter-button {
      padding: 10px 20px 12px;
      min-width: 120px;
      border: 1px solid #efefef;
      background: $white;
      border-radius: 3px;
      background-color: $white;
      font-size: 14px;
      font-weight: 300;
      color: $base;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      cursor: pointer;

      @include tablet {
        display: flex;
      }

      @include mobile {
        display: flex;
      }

      svg {
        margin-top: 2px;
        margin-right: 6px;
      }
    }

    .mobile-filters {
      margin-top: 10px;
      width: 100%;
      display: flex;

      .course-filters__search {
        margin-right: 20px;
        display: none;
        flex: 1;

        @include tablet {
          display: flex;
        }

        @include mobile {
          display: flex;
        }

        input {
          height: 100%;
        }
      }
    }

    .category-filter {
      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }
  }

  .catalogue {
    padding-top: 20px;
    padding-bottom: 100px;

    .u-container {
      display: flex;
      flex-direction: column;
    }

    .course-filters {
      margin-bottom: 30px;

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include mobile {
        margin: 0;
      }
    }

    .learning-path-card {
      margin: 10px;
      padding: 20px 20px 20px 16px;
      width: calc(50% - 20px);
      height: 200px;
      border-radius: 3px;
      border-left: 4px solid $white;
      background-color: $white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      @include mobile {
        margin: 10px 0;
        width: 100%;
      }

      &__label {
        margin-right: 4px;
        padding: 3px 10px 5px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        color: $base;
        white-space: nowrap;
      }

      &__length {
        color: $base;
        font-size: 16px;
        font-weight: 300;
      }

      h4 {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;
        color: $dark;
        text-transform: capitalize;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &:hover {
        box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.15);

        h4 {
          color: $primary;
        }
      }
    }
  }
}
