@import 'colors';
@import 'components/course-calendar/CourseCalendar.scss';
@import 'components/course-calendar-mobile/CourseCalendarMobile.scss';

.calendar-view {
  padding-bottom: 120px;
  background-color: $white;
  min-height: calc(100vh - 300px);

  @include tablet {
    background-color: $light-white;
  }

  @include mobile {
    background-color: $light-white;
  }

  main {
    position: relative;
    padding-top: 90px;
    padding-bottom: 40px;

    .block-intro {
      max-width: 380px;

      @include mobile {
        margin: 0 auto;
      }
    }

    .u-pattern {
      height: calc(100% + 150px);
      transform: translateY(-150px);
      background-size: contain;
      background-position: right top;
      background-image: url('/static/assets/patterns/calendar.svg');
    }
  }

  .calendar {
    padding-bottom: 150px;

    @include tablet {
      padding-bottom: 100px;
    }

    @include mobile {
      padding-bottom: 60px;
    }

    .course-calendar {
      display: flex;
      flex-direction: column;

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    .course-calendar-mobile {
      display: none;

      @include tablet {
        display: flex;
      }

      @include mobile {
        display: flex;
      }
    }
  }
}
