@import 'media';
@import 'colors';

.outcomes {
  padding-bottom: 130px;

  @include mobile {
    background-color: $light-white;
  }

  .block-intro {
    margin: 0 auto 40px;
    max-width: 480px;

    p {
      color: $dark;
    }

    @include mobile {
      margin-bottom: 0;
    }
  }

  &__grid {
    margin: 160px -10px 0;
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      margin-top: 100px;
    }

    @include mobile {
      margin-top: 100px;
    }

    .grid-item {
      margin: 10px;
      padding: 20px 30px 30px;
      width: calc(25% - 20px);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      align-items: center;
      text-align: center;

      @include tablet {
        padding: 20px 30px 20px;
        width: calc(50% - 20px);
      }

      @include mobile {
        padding: 20px 30px 30px;
        width: calc(100% - 20px);
      }

      &__icon {
        margin-bottom: 0;

        @include tablet {
          width: 80px !important;
          height: 80px !important;
        }
      }

      h4 {
        min-height: 50px;

        @include tablet {
          min-height: auto;
        }

        @include mobile {
          min-height: auto;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &__list {
    columns: 2;

    @include mobile {
      order: 1;
      columns: 1;
    }

    li {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;
      padding-left: 16px;
      color: $dark;
      font-size: 18px;
      line-height: 1.44;

      @include mobile {
        font-size: 16px;
        max-width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: $primary;

        @include mobile {
          top: 9px;
        }
      }
    }
  }
}
