@import 'colors';
@import 'media';

.category-filter {
  ul {
    display: flex;

    li {
      margin-bottom: 0;
    }
  }

  &__filter {
    position: relative;
    margin: 0 12px;
    padding: 10px 8px 19px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    color: $base;
    cursor: pointer;
    user-select: none;
    text-transform: capitalize;

    @media (min-width: $tablet-width) and (max-width: 1140px) {
      margin: 0 4px;
    }

    span {
      &:first-of-type {
        position: absolute;
      }

      &:last-of-type {
        color: transparent;
        font-weight: 700;
      }
    }

    &--selected {
      border-bottom: 5px solid $primary;
      font-weight: 700;
    }
  }
}
