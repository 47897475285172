@import 'colors';
@import 'media';

@import 'components/course-header/course-cta/CourseCTA.scss';

.details-header {
  position: relative;
  padding-bottom: 50px;

  @include tablet {
    padding-bottom: 40px;
  }

  @include mobile {
    padding-bottom: 0;
  }

  .u-bg {
    opacity: 0.9;
  }

  .u-photo {
    position: absolute;
    bottom: 0;
    left: 0;

    &--w-tabs {
      height: calc(100% - 101px);
    }
  }

  .u-pattern {
    background-size: contain;
    background-position: right top;

    &.course {
      background-image: url('/static/assets/patterns/course-details.svg');
    }

    &.path {
      background-image: url('/static/assets/patterns/learning-path-details.svg');
    }
  }

  &__tabs {
    position: relative;
    padding-top: 44px;

    @include mobile {
      padding-top: 30px;
    }

    &--light {
      background-color: $white;
    }

    .u-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    ul {
      width: 100%;
      display: flex;
      justify-content: center;

      @include mobile {
        padding-top: 20px;
      }
    }

    .tab {
      min-width: 260px;
      margin-bottom: 0;
      padding: 0 20px 17px;
      border-bottom: 5px solid $white;
      font-size: 18px;
      font-weight: 300;
      color: $base;
      text-align: center;
      line-height: 1;
      transition: border 0.2s ease-in-out;
      cursor: pointer;

      @include mobile {
        font-size: 16px;
        width: 50%;
        min-width: auto;
      }

      span {
        padding-left: 6px;

        @include mobile {
          padding-top: 4px;
          padding-left: 0;
          display: block;
          font-size: 13px;
        }
      }

      &--selected {
        font-weight: 700;
        color: $dark;
        border-color: $primary;
      }
    }
  }

  &__back-link {
    border: none;
    background: none;
    font-size: 12px;
    font-weight: 400;
    color: #cfd6ee;
    text-transform: uppercase;
    cursor: pointer;

    span {
      padding-left: 6px;
    }

    svg {
      margin-bottom: 1px;
      width: 4px;
      height: 7px;

      path {
        fill: #cfd6ee;
      }
    }
  }

  &__details {
    padding-top: 90px;

    @include tablet {
      padding-top: 0;
    }

    @include mobile {
      padding-top: 50px;
    }

    &--inactive {
      @include tablet {
        padding-bottom: 100px;
      }

      @include mobile {
        padding-bottom: 60px;
      }
    }

    .section {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;

      @include tablet {
        display: block;
        transform: translateY(100px);
      }
    }

    h1 {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 16px;
      color: $white;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 5px;
        width: 80px;
        background-color: $primary;
      }

      @include tablet {
        max-width: 80%;
        transform: translateY(100px);
      }

      @include mobile {
        font-size: 40px;
      }
    }
  }

  &__path {
    margin-bottom: 0;
    color: $white;

    @include tablet {
      transform: translateY(100px);
    }

    a {
      text-decoration: underline;
      text-transform: capitalize;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }
    }
  }

  &__descr {
    margin-top: 50px;
    padding-right: 120px;
    align-self: flex-start;
    flex: 1;

    @include desktop {
      padding-right: 60px;
    }

    @include tablet {
      margin-top: 30px;
      padding-right: 0;
      transform: translateY(100px);
    }

    @include mobile {
      margin-top: 30px;
      margin-bottom: 20px;
      padding-right: 0;
    }

    p {
      color: $white;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 30px;

      @include tablet {
        padding-right: 360px;
      }
    }
  }

  &__event {
    columns: 2;

    @include desktop {
      columns: 1;
    }

    @include tablet {
      columns: 1;
    }

    @include mobile {
      columns: 1;
    }

    &--row {
      columns: 1;
      display: flex;
      justify-content: space-between;
    }

    @include tablet {
      margin-top: 40px;
      flex-direction: column;
    }

    @include mobile {
      margin-top: 40px;
      flex-direction: column;
    }

    li {
      margin-bottom: 0;
      padding: 0 20px 8px 0;
      display: flex;
      color: $white;
      break-inside: avoid-column;

      &:last-of-type {
        padding-bottom: 0;
      }

      .light {
        color: #bcc3db;
      }

      span {
        padding-right: 8px;
        font-weight: 700;
        white-space: nowrap;
      }
    }
  }

  &__cta {
    width: 380px;
    align-self: flex-end;

    @include tablet {
      position: relative;
      z-index: 9;
      width: 340px;
    }

    @include mobile {
      width: 100%;
    }

    .course-cta__actions {
      @include mobile {
        margin-bottom: -20px;
      }
    }
  }
}
