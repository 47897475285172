@import 'colors';

@import 'components/custom-select/CustomSelect.scss';

.course-filters {
  margin: 0 -10px;
  display: flex;
  justify-content: space-between;

  .col {
    width: calc(25% - 20px);
    margin: 0 10px;
  }

  &__filters {
    width: 75%;
    display: flex;

    &--with-search {
      width: 50%;
    }
  }

  .custom-select {
    margin: 0 10px;
    min-width: 140px;

    &:first-of-type {
      width: calc(100% / 3 - 20px);

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }

    &:not(:first-of-type) {
      flex: 1;
    }
  }

  &__tech .custom-select {
    margin: 0;
    width: 100%;
    min-width: 140px !important;
  }
}
