@import 'colors';
@import 'media';

.signin {
  height: 100%;
  position: relative;
  padding-top: 190px;
  padding-bottom: 100px;
  background-color: $light-white;

  @include mobile {
    padding-top: 100px;
  }

  .u-bg {
    height: 240px;
    background-color: $internal;
  }

  .u-pattern {
    &.left {
      width: 60%;
      top: 100px;
      background-position: left top;
      background-image: url('/static/assets/patterns/login.svg');

      @include mobile {
        top: -50px;
        width: 100%;
      }
    }
  }

  &__form {
    margin: 0 auto;
    padding: 75px 100px;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    @include mobile {
      padding: 40px 20px;
    }
  }

  &__provider,
  &__signout {
    min-width: 300px;
    padding: 10px 12px 12px;
    border: 1px solid #d1dbe3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: $white;
    font-size: 14px;
    font-weight: 400;
    color: $dark;

    img {
      margin-top: 2px;
      margin-right: 10px;
    }

    @include mobile {
      min-width: auto;
      width: 100%;
    }
  }

  &__error {
    padding-top: 10px;
    width: 100%;
    color: $red;
    font-size: 14px;
    text-align: center;
  }
}
