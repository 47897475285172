@import 'colors';
@import 'media';

.main {
  padding: 10px 0;
  background-color: $lighter-gray;

  @include tablet {
    padding: 0;
  }

  @include mobile {
    padding: 0;
  }
}

.container {
  margin: 0 auto;
  max-width: 970px;
}

.banner {
  position: relative;
  display: flex;
  background-color: $white;
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  @include mobile {
    top: 2px;
    right: 2px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.left-side {
  padding: 10px 40px 10px 22px;
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
  background-color: $tertiary;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}

.title {
  margin-bottom: 0;
  color: $white;
  font-size: 24px;
  white-space: nowrap;

  @include mobile {
    font-size: 12px;
  }

  span {
    padding-top: 4px;
    display: block;
    font-size: 36px;
    text-transform: uppercase;

    @include mobile {
      font-size: 19px;
    }
  }
}

.right-side {
  padding: 20px 10px;
  display: flex;
  align-items: center;

  @include mobile {
    padding: 0;
  }
}

.description {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: $dark;

  @include mobile {
    display: none;
  }
}

.cta {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 20px 28px 20px 8px;
  }
}

.btn {
  padding: 8px 13px;
  width: 105px;
  border: none;
  border-radius: 5px;
  background-color: $tertiary;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $white;
  white-space: nowrap;
  cursor: pointer;
  transition: all ease-in 0.15s;

  &:hover {
    background-color: $tertiary-light;
  }

  @include mobile {
    width: 80px;
    font-size: 8px;
  }
}
