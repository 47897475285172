@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
