@import 'colors';
@import 'media';

@import 'components/category-filter/CategoryFilter.scss';
@import 'components/course-filters/CourseFilters.scss';
@import 'components/course-card/CourseCard.scss';
@import 'components/toggle-switch/ToggleSwitch.scss';
@import 'components/filter-sidebar/FilterSidebar.scss';

.courses {
  background-color: $light-white;
  padding-bottom: 100px;

  main {
    position: relative;
    padding-top: 90px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;

    @include tablet {
      box-shadow: none;
      background-color: $light-white;
    }

    @include mobile {
      box-shadow: none;
      background-color: $light-white;
    }

    .u-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .u-pattern {
      background-size: contain;

      &.left {
        background-position: left top;
        background-image: url('/static/assets/patterns/courses-left.svg');

        @include tablet {
          width: 40%;
        }

        @include mobile {
          width: 40%;
        }
      }

      &.right {
        background-position: right top;
        background-image: url('/static/assets/patterns/courses-right.svg');

        @include tablet {
          display: none;
        }

        @include mobile {
          display: none;
        }
      }
    }

    .block-intro {
      max-width: 580px;
      margin-bottom: 85px;

      @include tablet {
        margin-bottom: 0;
      }

      @include mobile {
        margin-bottom: 0;
      }
    }

    .mobile-filters {
      margin-top: 10px;
      width: 100%;
      display: flex;

      .course-filters__search {
        margin-right: 20px;
        display: none;
        flex: 1;

        @include tablet {
          display: flex;
        }

        @include mobile {
          display: flex;
        }

        input {
          height: 100%;
        }
      }
    }

    .category-filter {
      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }
  }

  .catalogue {
    position: relative;
    padding-top: 20px;

    .u-container {
      display: flex;
      flex-direction: column;
    }

    .course-filters {
      margin-bottom: 30px;

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    .loading {
      left: 0;
      height: calc(100% + 20px);
      background-color: $light-white;

      .loader::after {
        background-color: $light-white;
      }

      @include tablet {
        top: -10px;
      }

      @include mobile {
        top: -10px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
      }

      @include tablet {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
