.additional-info {
  position: relative;
  padding: 30px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  overflow: hidden;

  .loading {
    top: 0;
    left: 0;
  }

  @include tablet {
    padding: 20px;
  }

  @include mobile {
    padding: 20px;
  }

  li {
    display: flex;

    .details {
      padding-left: 20px;

      p {
        margin-bottom: 0;
        word-break: break-word;
      }
    }

    h4 {
      margin-bottom: 8px;
      padding-top: 6px;
      font-weight: 500;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
