@import 'colors';
@import 'media';

.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0.2s;

  &--visible {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    .modal__box {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.2s ease-in-out 0.1s, transform 0.2s ease-in-out 0.1s;
    }
  }

  @include mobile {
    display: block;
  }

  .u-container {
    display: flex;
    justify-content: center;
  }

  &__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px;
    border: none;
    background: none;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__box {
    position: relative;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 70px 70px 0;
    max-width: 580px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

    @include tablet {
      padding: 70px 30px 0;
    }

    @include mobile {
      margin-top: 10vh;
      margin-bottom: 70px;
      padding: 50px 20px 0;
    }

    h2,
    p {
      text-align: center;

      a {
        text-decoration: underline;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__cta {
    display: flex;
    transform: translateY(30px);

    .button {
      margin: 0 10px;
      padding: 10px 10px 12px;
      height: 60px;
      min-width: 180px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      box-shadow: none;

      @include mobile {
        padding: 10px 20px 12px;
        min-width: 100px;
      }
    }
  }
}
