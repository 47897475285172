@import 'colors';
@import 'media';

@import 'components/course-header/CourseHeader.scss';
@import 'components/topic-list/TopicList.scss';
@import 'components/additional-info/AdditionalInfo.scss';
@import 'components/path-course-card/PathCourseCard.scss';
@import 'components/lecturers/Lecturers.scss';
@import 'components/outcomes/Outcomes.scss';

.learning-path-details {
  background-color: $white;

  &__content {
    padding-bottom: 160px;

    @include tablet {
      padding-bottom: 100px;
    }

    @include mobile {
      padding-top: 60px;
      padding-bottom: 60px;
      background-color: $light-white;
    }

    &--inactive {
      @include mobile {
        padding-top: 0;
      }
    }

    .u-container {
      display: flex;

      @include mobile {
        flex-direction: column;
      }
    }

    section {
      padding-top: 100px;
      padding-right: 120px;
      flex: 1;

      @include desktop {
        padding-right: 60px;
      }

      @include tablet {
        padding-top: 70px;
        padding-right: 50px;
      }

      @include mobile {
        padding-top: 60px;
        padding-right: 0;
        order: 2;
      }

      .path-course-card {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    aside {
      width: 380px;
      transform: translateY(-20px);

      @include tablet {
        width: 340px;
      }

      @include mobile {
        width: 100%;
        transform: none;
        order: 1;
      }

      .instructors {
        margin-top: 40px;
      }
    }
  }

  &__outcomes {
    padding-bottom: 110px;

    .block-intro {
      margin: 0 auto 40px;
      max-width: 480px;
    }

    .outcomes__grid {
      display: flex;
      margin: 0 -10px;

      .grid-item {
        margin: 10px;
        padding: 40px 30px 20px;
        width: calc(25% - 20px);
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        text-align: center;

        h4 {
          height: 50px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
