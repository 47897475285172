@import 'colors';

.input-field {
  position: relative;

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__field {
    position: relative;
    padding-right: 40px;
    flex: 1;
    border: 1px solid $light-grey;
    border-radius: 3px;
    overflow: hidden;

    @include mobile {
      padding-right: 30px;
    }

    svg {
      position: absolute;
      right: 20px;
      top: 50%;
      display: none;
      transform: translate(0, -50%);

      @include mobile {
        right: 10px;
      }
    }
  }

  &__label {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: $base;
    z-index: 9;
    transition: all 0.2s ease-in-out;

    @include mobile {
      left: 10px;
    }
  }

  p {
    margin-bottom: 0;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input,
  p {
    padding: 27px 20px 10px;
    height: 60px;
    width: 100%;
    font-size: 16px;
    color: $base;
    border: none;

    @include mobile {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__upload {
    margin-left: 12px;
    padding: 8px 14px;
    height: 40px;
    min-width: 120px;
    border: 1px solid $light-grey;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: $base;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: $base;
    }

    input {
      display: none;
    }
  }

  &__error {
    position: absolute;
    bottom: 6px;
    color: $red;
    font-size: 12px;
    font-weight: 400;
  }

  &--dirty {
    .input-field__label {
      top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #8c90a3;
    }
  }

  &--invalid {
    .input-field {
      &__field {
        border-color: $red;

        svg {
          display: block;
        }
      }
    }
  }
}
