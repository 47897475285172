$dark: #172762;
$dark-2: #081132;
$base: #444c68;
$red: #ff0b53;
$success: #08ea8c;
$primary: #156ef4;
$secondary: #3521b5;
$tertiary: #3c89fa;
$tertiary-light: #0c6cf8;
$light-blue: #c5e8f1;
$white: #fff;
$grey: #c2c8d2;
$light-grey: #efefef;
$lighter-gray: #f5f5f6;
$light-white: #fafafb;
$blue-gradient-top: #4c0cf9;
$blue-gradient-bottom: #020b88;
$internal: #630fb7;
