@import 'colors';
@import 'media';

.policy {
  padding: 80px 0 100px;
  background-color: #fafafb;
  scroll-behavior: smooth;

  &__content {
    padding: 60px 40px;
    background-color: $white;

    @include mobile {
      padding: 40px 20px;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 500;
  }

  p {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.63;
    color: $dark;

    @include mobile {
      font-size: 14px;
    }

    a {
      color: $dark;
      text-decoration: underline;
      cursor: pointer;
      transition: color 0.2s ease-out;

      &:hover {
        @include desktop-hd {
          color: $primary;
        }

        @include desktop {
          color: $primary;
        }
      }
    }
  }

  h2 {
    font-size: 18px;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
  }

  h4 {
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;
  }

  .section {
    padding: 16px 0;
  }

  .sub-section {
    padding: 12px 0 8px;

    p {
      margin-bottom: 16px;
    }
  }

  &__bullet-list {
    .item {
      position: relative;
      padding-left: 42px;

      @include mobile {
        padding-left: 24px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 17px;
        top: 9px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $dark;

        @include mobile {
          left: 8px;
          top: 10px;
          width: 4px;
          height: 4px;
          border-radius: 2px;
        }
      }
    }
  }

  &__table {
    margin-bottom: 12px;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    th {
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      font-family: 'Jost', sans-serif;
      color: $dark;
      background-color: $light-grey;
      text-align: left;
      border: 1px solid $light-grey;

      @include mobile {
        padding: 1px;
        font-size: 11px;
      }
    }

    td {
      padding: 6px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.63;
      color: $dark;
      border: 1px solid $light-grey;

      @include mobile {
        padding: 1px;
        font-size: 11px;
      }

      &:first-of-type {
        min-width: 200px;

        @include mobile {
          min-width: 0;
        }
      }

      &:nth-child(3) {
        min-width: 160px;

        @include mobile {
          min-width: 0;
        }
      }
    }
  }
}
