@import 'colors';
@import 'media';

@import 'components/input-field/InputField.scss';
@import 'components/checkbox-field/CheckboxField.scss';

.registration-form {
  padding: 30px 30px 0;
  width: 100%;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.1);

  &--loading {
    pointer-events: none;
  }

  @include mobile {
    padding: 30px 10px 0;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;

    .input-field {
      &:first-of-type {
        flex: 1;
        margin-right: 20px;
      }

      &:last-of-type {
        flex: 1;
      }
    }
  }

  &__note {
    color: #444c68;
    opacity: 0.5;
    font-size: 11px;
    padding-left: 2px;
  }

  &__cv {
    margin-bottom: 24px;
    padding-right: 40px;
    border: 1px solid $light-grey;
    border-radius: 3px;

    .upload-button {
      padding: 8px 14px;
      height: 40px;
      min-width: 120px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      color: $base;
      text-align: center;
      cursor: pointer;

      input {
        display: none;
      }
    }

    .cv-file {
      padding-left: 20px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__cv-row {
    display: flex;
    align-items: center;

    .input-field {
      flex: 1;
    }

    .divider,
    .upload-button {
      margin-bottom: 24px;
    }

    .divider {
      padding: 0 10px;
      font-size: 14px;
      font-weight: 400;
      color: $base;
    }

    .upload-button {
      padding: 8px 14px;
      height: 40px;
      min-width: 120px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      color: $base;
      text-align: center;
      cursor: pointer;

      input {
        display: none;
      }
    }

    .cv-file {
      padding-left: 20px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__recaptcha {
    font-size: 11px;
    color: $base;
    opacity: 0.5;

    a {
      text-decoration: underline;

      &:hover {
        color: $primary;
      }
    }
  }

  &__success {
    margin-bottom: 0;
    font-size: 16px;
    color: $primary;
    font-weight: 700;
  }

  &__error {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: $red;
    display: flex;

    span {
      padding-left: 10px;
    }
  }

  .button {
    height: 60px;
    min-width: 180px;
    font-size: 16px;
    transform: translateY(50%);
    align-self: flex-end;

    @include mobile {
      width: 100%;
    }
  }
}
