@import 'components/modal/Modal.scss';

.course-cta {
  &__actions {
    position: relative;
    padding: 22px 20px;
    width: 100%;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
  }

  &__actions--multiple {
    flex-wrap: wrap;

    @include mobile {
      flex-direction: column;
    }

    .button {
      @include mobile {
        margin-top: 20px;
        padding: 10px 10px;
        min-width: 120px;
        width: 100%;
        font-size: 12px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  .u-type-label {
    position: relative;
    top: auto;
    left: -20px;
  }

  .button {
    width: calc(50% - 10px);
    min-width: 150px;
    padding: 10px 8px;
    text-align: center;
    white-space: nowrap;

    @include mobile {
      min-width: 140px;
    }
  }

  &__type-info {
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $base;
    font-weight: 600;
    transform: translateX(-10px);

    .price {
      font-size: 18px;

      &--lg {
        font-size: 30px;
      }
    }
  }

  .details {
    font-size: 14px;
    line-height: 1.17;
    color: $base;

    mark {
      font-weight: 700;
    }

    @include mobile {
      font-size: 12px;
    }

    &--small {
      font-size: 10px;
    }
  }

  &__note {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 100%;
    text-align: center;

    .link {
      font-size: 10px;
    }
  }

  .modal {
    &--info {
      .modal__close-button {
        display: none;
      }

      .block-intro__descr {
        margin-bottom: 10px;
      }
    }

    &--roles {
      .modal__box {
        max-width: 86%;

        @include mobile {
          max-width: 100%;
        }
      }
    }

    &__custom-content {
      padding-bottom: 70px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      .list {
        width: calc(50% - 20px);

        @include mobile {
          width: 100%;
        }
      }

      ul {
        list-style-type: initial;
        padding-left: 30px;

        li {
          margin-bottom: 10px;

          &::marker {
            color: $primary;
            font-weight: bold;
          }
        }
      }
    }
  }
}
