@import 'fonts';

@import '~react-calendar/dist/Calendar.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import 'colors';
@import 'media';

@import 'node_modules/react-modal-video/scss/modal-video.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

// ! If this is used it will break the whole design and It will have to be upadted
// * Responsive font size
// html {
//   font-size: 62.5%; // 1rem = 10px

//   @include tablet {
//     font-size: 50%;
//   }

//   @include mobile {
//     font-size: 45%;
//   }

// }

a {
  color: inherit;
  text-decoration: none;
  user-select: none;
}

body {
  font-family: 'Jost', sans-serif;
  background: $light-white;
  min-width: 320px;

  @include tablet {
    &.no-scroll {
      overflow: hidden;
    }
  }

  @include mobile {
    &.no-scroll {
      overflow: hidden;
    }
  }
}

ul {
  list-style-type: none;
}

button {
  font-family: 'Jost', sans-serif;
  cursor: pointer;
  user-select: none;
}

textarea {
  font-family: 'Jost', sans-serif;
  resize: none;
}

select {
  font-family: 'Jost', sans-serif;
}

input {
  font-family: 'Jost', sans-serif;
  -webkit-appearance: none;

  &::placeholder {
    font-family: 'Jost', sans-serif;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: 'Jost', sans-serif;
    box-shadow: 0 0 0 30px white inset !important;
  }
}

h1 {
  margin-bottom: 36px;
  font-size: 44px;
  font-weight: 700;
  line-height: 0.96;
  color: $dark;

  @include tablet {
    font-size: 42px;
  }

  @include mobile {
    font-size: 32px;
  }
}

h2 {
  margin-bottom: 32px;
  font-size: 34px;
  font-weight: 700;
  color: $dark;

  @include mobile {
    font-size: 28px;
  }
}

// TODO: fix heading tag rules
h3 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.44;
  color: $dark;
}

h4 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: $dark;
}

h5 {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.44;
  color: $dark;
}

h6 {
  font-size: 9px;
  font-weight: 400;
  letter-spacing: 1.35px;
  text-transform: uppercase;
  color: #222a35;
}

li,
p {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  color: $base;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-container {
  position: relative;
  margin: 0 auto;
  padding: 0 130px;
  width: 100%;

  @include desktop-hd {
    max-width: 1440px;
  }

  @include desktop {
    padding: 0 70px;
  }

  @include tablet {
    padding: 0 20px;
  }

  @include mobile {
    padding: 0 20px !important;
  }
}

// TODO: move util classes to file
.u-col-container {
  position: relative;
  display: flex;

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    flex-direction: column;
  }
}

.u-col {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;

  @include tablet {
    width: 100%;
  }

  @include mobile {
    width: 100%;
  }

  &--left {
    .u-container {
      margin: 0;
      padding-right: 60px;
      max-width: calc(1440px / 2);
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      align-self: flex-end;

      @include tablet {
        padding-right: 20px;
        max-width: 100%;
      }

      @include mobile {
        padding-right: 20px;
        max-width: 100%;
      }

      &--custom {
        @include desktop-hd {
          padding-left: calc((100vw - 1440px) / 2 + 130px);
          max-width: none;
        }
      }
    }
  }

  &--right {
    .u-container {
      margin: 0;
      padding-left: 60px;
      max-width: calc(1440px / 2);
      flex: 1;
      align-self: flex-start;

      @include tablet {
        padding-left: 20px;
        max-width: 100%;
      }

      @include mobile {
        padding-left: 20px;
        max-width: 100%;
      }

      &--custom {
        @include desktop-hd {
          padding-right: calc((100vw - 1440px) / 2 + 130px);
          max-width: none;
        }
      }
    }
  }
}

.course-filters__search form {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .input__field {
    padding: 8px 12px 8px 40px;
    border: 1px solid #efefef;

    &::placeholder {
      color: $base;
      font-weight: 300;
    }
  }

  button {
    position: absolute;
    left: 16px;
    border: none;
    background: none;
  }
}

.input {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  &__field {
    padding: 10px 12px 12px;
    width: 100%;
    border: 1px solid $grey;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: $base;
    overflow: hidden;
  }

  &::placeholder {
    color: $base;
    font-weight: 300;
  }

  &__error {
    position: absolute;
    display: block;
    width: 100%;
    color: $red;
    font-size: 12px;
  }

  &__success {
    position: absolute;
    display: block;
    color: $primary;
    font-size: 12px;
  }
}

.button {
  padding: 10px 40px;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &--primary {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;

    &:hover {
      border: 1px solid $white;
      background: $white;
      color: $primary;
    }

    &.button--disabled {
      color: $white;
      background-color: #efefef;
      border: 1px solid #efefef;
      pointer-events: none;
      cursor: auto;
    }
  }

  &--disabled-filled {
    color: $white;
    background-color: #efefef;
    border: 1px solid #efefef;
    pointer-events: none;
    cursor: auto;
  }

  &--border {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;

    &:hover {
      background: $primary;
      color: $white;
    }

    &.button--disabled {
      color: #c2c8d2;
      border: 1px solid #c2c8d2;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &--dark {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    border: 2px solid $base;
    background-color: $white;
    color: $base;

    &:hover {
      background: $base;
      color: $white;
    }
  }

  &--light {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    border: 1px solid #d1dbe3;
    color: $base;
  }
}

.link {
  padding: 0 10px 2px;
  font-size: 16px;
  font-weight: 400;
  color: $dark;
  background: none;
  border: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }

  &--underlined {
    text-decoration: underline;
  }

  &--active {
    color: $primary;
  }
}

.u-arrow-button {
  display: flex;
  height: 30px;
  width: 30px;
  background: none;
  border: none;

  svg {
    width: 100%;
    height: 100%;
  }

  &--next {
    transform: rotate(180deg);
  }

  &--active {
    cursor: pointer;

    svg path {
      fill: $primary;
    }
  }
}

.u-pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.u-photo {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-image: url('/static/assets/');
}

.u-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--gradient-dark {
    background-image: linear-gradient(to top, $secondary, #5138ee);
  }

  &--gradient-dark-internal {
    background-color: #7830e6;
    opacity: 0.9;
  }

  &--gradient-internal {
    height: 250px;
    transform: translateY(-40px);
    opacity: 0.7;
    background-image: linear-gradient(
      to bottom,
      rgba(120, 48, 230, 0.8),
      rgba(120, 48, 230, 0.2),
      rgba(120, 48, 230, 0)
    );
  }

  &--gradient-primary {
    height: 180px;
    transform: translateY(-40px);
    opacity: 0.9;
    background-image: linear-gradient(
      to top,
      rgba(12, 226, 237, 0),
      rgba(12, 226, 237, 0.2)
    );
  }

  &--gradient-purple {
    height: 180px;
    transform: translateY(-40px);
    opacity: 0.9;
    background-image: linear-gradient(
      to top,
      rgba(96, 16, 158, 0),
      rgba(96, 16, 158, 0.2)
    );
  }
}

.u-type-icon {
  &--sponsored {
    background-color: #bef2e5;
  }

  &--tuition-fee {
    background-color: #c5e8f1;
  }

  &--money-back {
    padding-left: 3px;
    background-color: #ffe2db;
  }
}

.u-get-started {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 100px 0 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/static/assets/patterns/get-started.svg');

  @include tablet {
    padding: 50px 0 40px;
  }

  @include mobile {
    padding: 50px 0 40px;
  }
}

.course-filters__button {
  padding: 10px 20px 12px;
  min-width: 120px;
  border: 1px solid #efefef;
  background: $white;
  border-radius: 3px;
  background-color: $white;
  font-size: 14px;
  font-weight: 300;
  color: $base;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;

  @include tablet {
    display: flex;
  }

  @include mobile {
    display: flex;
  }

  svg {
    margin-top: 2px;
    margin-right: 6px;
  }
}

.catalogue__empty {
  margin: 36px auto;
  max-width: 380px;
  opacity: 0.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin-top: 24px;
  }
}

.app {
  padding-top: 100px;

  &.banner-visible {
    padding-top: 200px;
  }

  @include mobile {
    padding-top: 80px;

    &.banner-visible {
      padding-top: 148px;
    }
  }

  &__page {
    position: relative;
    min-height: calc(100vh - 296px);
  }

  &__version {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.71;
    background-color: $base;
    color: $white;
  }
}

.not-found-page {
  position: relative;
  padding: 100px 0;
  min-height: calc(100vh - 500px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .u-pattern {
    height: 60%;
    background-size: contain;
    background-position: right top;
    background-image: url('/static/assets/patterns/404.svg');

    @include mobile {
      height: 30%;
    }
  }

  .block-intro {
    padding-bottom: 100px;
    width: 100%;
    max-width: 380px;

    @include mobile {
      margin: 0 auto;
    }
  }
}

// React-Slick overwrites

.testimonial-carousel .slick-dots {
  bottom: -60px;

  li {
    border: 1px solid $dark;
    border-radius: 50%;
    width: 12px;
    height: 12px;

    button::before {
      content: '';
    }

    &.slick-active {
      background-color: $dark;
    }
  }
}
