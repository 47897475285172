@import 'colors';
@import 'media';

.site-footer {
  padding-top: 68px;
  background-color: $secondary;

  p,
  a {
    color: $white;
    font-weight: 300;
  }

  a {
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $primary;
    }
  }

  li {
    margin-bottom: 10px;
  }

  .u-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .top {
    margin: 80px 0 70px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include mobile {
      margin: 0;
      flex-direction: column;
    }
  }

  .bottom {
    padding: 14px 0;
    width: 100%;
    border-top: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: rgba(237, 237, 237, 0.6);

    @include mobile {
      padding-bottom: 40px;
    }
  }

  .col {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-bottom: 30px;
      width: 100%;

      &:last-of-type {
        margin-top: 30px;
      }
    }

    .header {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #c0c0c0;
    }
  }

  &__locations {
    padding: 0 20px;
    align-items: center;

    @include mobile {
      order: 3;
      padding: 0;
      align-items: flex-start;
    }
  }

  &__subscribe {
    @include tablet {
      margin-top: 30px;
      width: 100% !important;
    }

    @include mobile {
      order: 1;
    }

    form {
      display: flex;
    }

    button {
      margin-left: 10px;
      padding: 4px 6px 6px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid $white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      background-color: transparent;
      color: $white;
      transition: all 0.2s ease-in-out;

      @include tablet {
        padding: 4px 30px 6px;
      }

      &:hover {
        background: $primary;
      }
    }

    .input {
      &__field {
        padding: 8px 12px 10px;
        flex: 1;
        background: transparent;
        color: $white;

        &::placeholder {
          color: $white;
          font-weight: 300;
        }
      }
    }
  }

  &__contacts {
    @include tablet {
      width: calc(100% / 3 * 2) !important;
    }

    @include mobile {
      order: 2;
    }

    .phone,
    .email {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.35;

      span {
        padding-right: 6px;
      }
    }
  }

  .bottom {
    position: relative;
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      margin-bottom: 0;
    }
  }

  &__legal {
    display: flex;

    li {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    @include mobile {
      flex-direction: column;
      margin: 6px 0 10px;
      order: 2;
    }

    li a {
      font-size: 12px;
      color: rgba(237, 237, 237, 0.6);
    }
  }

  &__copyright {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);

    @include mobile {
      order: 3;
      position: relative;
      transform: none;
      left: auto;
      margin-right: 0;
    }
  }

  &__social {
    display: flex;
    transform: translateX(4px);

    @include mobile {
      order: 1;
      transform: translateX(-4px);
    }

    a {
      margin-left: 4px;
      padding: 4px;
      height: 28px;
      width: 28px;
      display: flex;

      @include mobile {
        margin: 0 4px;
      }

      &:hover svg path {
        fill: $primary;
      }
    }
  }

  &--internal {
    background-color: $internal;

    .u-container {
      align-items: center;
    }

    .site-footer__logo-link {
      margin-bottom: 40px;
    }
  }
}
