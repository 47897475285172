@import 'colors';

.custom-select {
  position: relative;
  min-width: 126px;
  user-select: none;

  &__select {
    position: relative;
    padding: 8px 12px 8px 20px;
    border: 1px solid #efefef;
    border-radius: 3px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
    color: $base;
    cursor: pointer;
    user-select: none;

    span {
      padding-right: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-top: 2px;
      height: 6px;
      width: 10px;
      transition: all 0.2s ease-in-out;

      path {
        fill: $base;
      }
    }

    &--selected span {
      font-weight: 500;
    }
  }

  &__option {
    padding: 8px 12px 8px 20px;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    color: $dark;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $primary;
    }

    &--selected {
      color: $primary;
    }
  }

  &__options {
    position: absolute;
    z-index: 99;
    padding: 3px 0;
    width: 100%;
    max-height: 230px;
    border: 1px solid #efefef;
    border-radius: 3px;
    background-color: $white;
    display: none;
    overflow: scroll;
    transform: translateY(-4px);
  }

  &--active &__options {
    display: block;
    border-top-color: $white;
  }

  &--active &__select {
    border-bottom-color: $white;

    span {
      font-weight: 600;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    .custom-select {
      &--select {
        color: $primary;
        font-weight: 600;
      }
    }
  }
}
