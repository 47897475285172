@import 'colors';
@import 'media';

$itemCount: 6;

.course-suggestions {
  .top {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__navigation {
    display: flex;
    align-items: center;

    @include mobile {
      display: none;
    }
  }

  .arrow-button {
    height: 30px;
    width: 30px;

    &--next {
      margin-left: 10px;
      transform: rotate(180deg);
    }

    &--active {
      cursor: pointer;

      svg path {
        fill: $primary;
      }
    }
  }

  .block-intro h2 {
    @include mobile {
      justify-content: flex-start;
    }
  }

  &__card-container {
    width: 100%;
    overflow: hidden;
  }

  &__list {
    width: calc(#{$itemCount} * 25%);
    display: flex;
    transition: transform 0.4s ease-in-out;
    margin: 0 -10px;

    @include tablet {
      width: calc(#{$itemCount} * (100% / 3));
    }

    @include mobile {
      flex-direction: column;
      width: 100%;
    }

    &--active {
      @for $i from 0 through $itemCount {
        &-#{$i} {
          transform: translateX(calc(-100% / $itemCount * $i));

          @include mobile {
            transform: none;
          }
        }
      }
    }
  }

  &__course-card {
    width: calc(100% / 6 - 20px);
    margin: 0 10px;
    transform: scale(0.6);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    @include mobile {
      margin: 20px 10px;
      width: 100%;
      display: none;
    }

    h4 {
      min-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      transition: all 0.2s ease-in-out;

      @include mobile {
        min-height: auto;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .type {
      margin-bottom: 4px;
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #c2c8d2;
    }

    &--active {
      transform: scale(1);
      opacity: 1;
      transition: all 0.3s ease-in-out;

      @include mobile {
        display: block;
      }
    }

    &:hover {
      .cover-photo .u-photo {
        transform: scale(1.2);
      }

      h4 {
        color: $primary;
      }
    }
  }

  &__event {
    position: relative;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .dates {
      padding: 2px 14px;
      font-size: 12px;
      color: $white;
      border: 1px solid $dark;
      border-radius: 12px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      color: $dark;
    }

    .u-type-label {
      position: relative;
      right: auto;
      top: auto;
      margin-left: 10px;
    }
  }

  .cover-photo {
    margin-bottom: 20px;
    width: 100%;
    height: 165px;
    background-color: $white;
    border-radius: 3px;
    overflow: hidden;

    .u-photo {
      position: relative;
      transition: all 0.2s ease-in-out;
    }
  }
}
