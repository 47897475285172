@import 'colors';
@import 'media';

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
  will-change: transform;

  &--w-banner {
    transform: translateY(-122px);

    @include tablet {
      transform: translateY(-102px);
    }

    @include mobile {
      transform: translateY(-68px);
    }
  }

  &--default {
    transform: translateY(0);
  }

  &--out {
    transition: transform 0.5s ease-in-out, background-color 0.4s ease-in-out,
      box-shadow 0.4s ease-in-out;
    transform: translateY(-110%);
  }
}

.navigation {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;

  @include mobile {
    transition: transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out,
      height 0s linear 0.4s;
    height: 80px;
  }

  &__content {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 3px 4px 24px 0 rgba(14, 14, 39, 0.25);

    @include mobile {
      height: 80px;
    }
  }

  &--mobile {
    @include mobile {
      height: 100%;
      transition: transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out,
        height 0s linear 0s;
    }
  }

  .u-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  a {
    display: flex;
  }

  &__logo {
    width: 244px;

    @include mobile {
      width: 200px;
    }
  }

  &__menu-button {
    position: absolute;
    width: 22px;
    height: 19px;
    right: 20px;
    display: none;
    cursor: pointer;
    transition-duration: 0.3s;

    @include mobile {
      display: block;
    }

    .icon,
    .icon:before,
    .icon:after {
      position: absolute;
      width: 22px;
      height: 3px;
      border-radius: 3px;
      background-color: $dark;
      transition-duration: 0.3s;
    }

    .icon {
      top: 8px;
      left: -6px;
      width: 16px;

      &:before {
        content: '';
        top: -8px;
      }

      &:after {
        content: '';
        top: 8px;
      }
    }

    &--open {
      .icon {
        transition-duration: 0.1s;
        background: transparent;

        &:before {
          transform: rotateZ(45deg) scaleX(1.25) translate(5px, 6px);
        }

        &:after {
          transform: rotateZ(-45deg) scaleX(1.25) translate(4px, -5px);
        }
      }
    }
  }

  &__menu {
    display: flex;

    @include mobile {
      position: fixed;
      top: 80px;
      left: 0;
      padding: 60px 20px;
      height: calc(100vh - 80px);
      width: 100vw;
      background: #fff;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transform: translateX(100vw);
      transition: transform 0.3s ease-in-out;
      will-change: transform;
      overflow: auto;

      &--visible {
        display: flex;
        transform: translateX(0);
      }

      &--banner-visible {
        margin-top: 104px;
        top: 44px;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    li {
      margin: 0 12px;

      @include mobile {
        margin: 5px 0;

        .link {
          padding: 10px !important;
        }
      }

      &:last-of-type {
        margin-right: 0;

        .link {
          padding-right: 0;
        }
      }
    }
  }

  &__profile {
    position: relative;
    margin-left: 40px;

    @include mobile {
      display: none;
    }

    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);

      img {
        height: 100%;
        width: 100%;
      }
    }

    &:hover {
      .dropdown {
        display: flex;
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out,
          visibility 0s linear;
      }
    }

    .dropdown {
      position: absolute;
      right: 0;
      min-width: 180px;
      padding-top: 40px;
      opacity: 0;
      visibility: hidden;
      transform: scale(0);
      transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out,
        visibility 0.2s linear;

      button {
        position: relative;
        padding: 10px 20px 12px;
        width: 100%;
        border: none;
        font-size: 16px;
        font-weight: 400;
        color: $dark;
        text-align: left;
        background-color: $white;
        border-radius: 3px;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:after {
          position: absolute;
          right: 10px;
          top: 0;
          content: '';
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          transform: translateY(-100%);
          border-bottom: 8px solid $white;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__logout {
    display: none;

    @include mobile {
      display: block;
      font-size: 18px;
      font-weight: 400;
      color: #c2c8d2;
    }
  }
}
