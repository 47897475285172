@import 'colors';
@import 'media';

@import 'components/registration-form/RegistrationForm.scss';

.apply {
  position: relative;
  padding: 90px 0 150px;

  .u-pattern {
    height: 500px;
    max-width: 80%;
    background-size: contain;
    background-position: left top;
    background-image: url('/static/assets/patterns/registration.svg');
  }

  .u-col-container {
    @include desktop-hd {
      padding: 0 80px;
    }
  }

  .u-col--left {
    width: 57%;

    @include tablet {
      width: 100%;
      order: 2;
    }

    @include mobile {
      width: 100%;
      order: 2;
    }
  }

  .u-col--right {
    width: 43%;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    .u-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .block-intro {
    @include desktop-hd {
      h1 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
      }
    }

    @include desktop {
      h1 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
      }
    }

    @include tablet {
      align-items: center;
      text-align: center;
    }

    @include mobile {
      align-items: center;
      text-align: center;
    }
  }

  &__questions {
    margin-top: 30px;

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }

    a {
      color: $base;
      font-size: 20px;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }
    }

    p {
      margin-top: 8px;
    }
  }
}
