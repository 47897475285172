@import 'colors';
@import 'media';

.block-intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1,
  h2,
  h3 {
    line-height: 1.17;
  }

  &--centered {
    align-items: center;
    text-align: center;

    .block-intro__title {
      display: flex;
      justify-content: center;
    }
  }

  &--light {
    h1,
    h2,
    h3,
    p {
      color: $white;
    }
  }

  @include mobile {
    align-items: center;
    text-align: center;

    .block-intro__title {
      display: flex;
      justify-content: center;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 16px;

    &--underline {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 5px;
        width: 80px;
        background-color: $primary;
      }
    }
  }

  h1:before {
    display: none;
  }

  &__descr {
    margin-bottom: 38px;
    max-width: 740px;
    font-size: 18px;

    @include mobile {
      font-size: 16px;
    }
  }
}
