@import 'colors';

.path-course-card {
  padding-top: 25px;
  padding-bottom: 34px;
  border-bottom: 2px dashed #dddddd;

  &:last-of-type {
    border-bottom: none;
  }

  &__cover-photo {
    height: 100px;
    width: 180px;
    border-radius: 3px;

    @include tablet {
      width: 100%;
      height: 180px;
    }

    @include mobile {
      width: 100%;
      height: 180px;
    }
  }

  &__header {
    margin-bottom: 28px;
    display: flex;
    align-items: center;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    h4 {
      margin-bottom: 0;
      padding: 0 20px;
      flex: 1;

      @include tablet {
        margin-top: 10px;
        padding: 0;
      }

      @include mobile {
        margin-top: 10px;
        padding: 0;
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #c2c8d2;
      }
    }
  }

  &__topics {
    display: flex;
    flex-wrap: wrap;

    ul {
      width: 50%;
      margin-bottom: 0;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }

    li {
      margin-bottom: 12px;
      line-height: 1.4;
    }
  }
}
