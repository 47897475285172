.checkbox-field {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9;

  &__box {
    position: relative;
    padding-left: 26px;
    user-select: none;

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.43;
      color: $base;
      cursor: pointer;

      a {
        position: relative;
        z-index: 9;
        text-decoration: underline;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $primary;
        }
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      opacity: 0;
      height: 20px;
      width: 20px;
      cursor: pointer;

      &:checked ~ .checkbox-field__mark:after {
        display: block;
      }
    }
  }

  &__mark {
    position: absolute;
    z-index: -1;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    border: solid 1px $base;
    border-radius: 3px;
    background-color: $white;

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 8px;
      border: solid $primary;
      display: none;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__error {
    position: absolute;
    bottom: -16px;
    left: 26px;
    color: $red;
    font-size: 12px;
    font-weight: 400;
  }
}
