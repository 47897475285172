@import 'colors';

.loading {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .loader {
    position: relative;
    margin: 50px auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $primary;
    background: linear-gradient(
      to right,
      $primary 10%,
      rgba(255, 255, 255, 0) 50%
    );
    animation: spin 1s infinite linear;
    transform: translateZ(0);

    &:after {
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 75%;
      height: 75%;
      background: $white;
      border-radius: 50%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 50%;
      background: $primary;
      border-radius: 100% 0 0 0;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
