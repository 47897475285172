@import 'colors';
@import 'media';

.filter-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0s linear 0.6s;
  display: none;

  @include tablet {
    display: flex;
  }

  @include mobile {
    display: flex;
  }

  &--active {
    transform: translateX(0);
    transition: transform 0s linear;

    .filter-sidebar {
      &__backdrop {
        opacity: 1;
      }

      &__filters {
        transform: translateX(0);
        transition: transform 0.4s ease-in-out;
      }
    }
  }

  &__backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.9);
    transition: opacity 0.2s ease-in-out;
  }

  &__filters {
    padding: 70px 20px 70px;
    width: calc(100% - 80px);
    max-width: 400px;
    height: 100%;
    background-color: $white;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);
    overflow: scroll;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    will-change: transform;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 99999;
  }

  .course-filters {
    flex-direction: column;
    margin: 0;

    .col {
      width: 100%;
      margin: 0;
    }

    &__filters {
      flex-direction: column;
      width: 100%;
    }

    &__toggle {
      padding: 15px 0;
    }
  }

  .custom-select {
    position: relative;
    width: 100%;
    margin: 0;
    user-select: none;
    border-bottom: 1px dashed $grey;

    &__select {
      position: relative;
      padding: 12px 0;
      border: none;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark;

      &--value {
        color: $primary;
      }

      span {
        padding-right: 12px;
      }

      svg {
        margin-top: 2px;
        height: 10px;
        width: 14px;
        transition: all 0.2s ease-in-out;

        path {
          fill: $grey;
        }
      }
    }

    &__option {
      padding: 6px 0;
      font-weight: 400;
      color: $base;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease-in-out;

      &:last-of-type {
        margin-bottom: 10px;
      }

      &:hover {
        color: $primary;
      }

      &--selected {
        color: $primary;
      }

      @include mobile {
        font-size: 16px;
      }
    }

    &__options {
      position: relative;
      padding: 0 10px;
      max-height: 0;
      border: none;
      display: block;
      opacity: 0;
      transition: max-height 0.1s ease-out 0.1s, opacity 0.2s ease-out;
      overflow: hidden;
    }

    &--active {
      .custom-select {
        &__select {
          border-bottom-color: $white;

          span {
            font-weight: 600;
          }

          svg {
            transform: rotate(180deg);
          }
        }

        &__options {
          transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
          max-height: 3000px;
          opacity: 1;
        }
      }
    }

    &:hover {
      .custom-select {
        &--select {
          color: #156ef4;
          font-weight: 600;
        }
      }
    }
  }
}
