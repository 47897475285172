@import 'colors';
@import 'media';

.course-card {
  position: relative;
  width: 100%;
  height: 160px;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.15);

    h4 {
      color: $primary;
    }
  }

  &__content {
    padding: 20px 20px 16px 16px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
  }

  &__top {
    width: 100%;
  }

  &__events {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__date {
    margin: 2px 4px 2px 0;
    padding: 2px 8px;
    font-size: 10px;
    color: $white;
    background-color: $secondary;
    border-radius: 10px;
    white-space: nowrap;
  }

  .u-type-label {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 6px 8px 6px 12px;
    display: flex;
    align-items: center;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    line-height: 1;
    font-size: 12px;
    color: $dark;
    transform: translateX(20px);
    text-transform: uppercase;

    svg {
      margin-left: 6px;
    }
  }

  &__details {
    span {
      position: relative;
      margin-right: 4px;
      padding: 3px 0 3px 16px;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      color: $base;
      white-space: nowrap;

      &::before {
        content: '';
        position: absolute;
        height: 4px;
        width: 4px;
        left: 4px;
        border-radius: 50%;
        background-color: $base;
      }

      &:first-of-type {
        padding-left: 0;

        &::before {
          display: none;
        }
      }

      @include tablet {
        font-size: 11px;
      }
    }
  }

  h4 {
    margin-bottom: 0;
    padding-top: 10px;
    font-weight: 400;
    line-height: 1.3;
    color: $dark;
    white-space: normal;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
