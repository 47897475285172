@import 'colors';

.toggle-switch {
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;

  p {
    margin-bottom: 2px;
    padding-left: 10px;
    font-weight: 300;

    @include mobile {
      font-size: 12px;
    }
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input[type='checkbox'] {
      display: none;

      &:checked + .switch::before {
        transform: translateX(22px);
        background-color: #fff;
      }

      &:checked + .switch {
        background-color: $primary;
      }
    }

    .switch {
      position: absolute;
      cursor: pointer;
      background-color: #dfdfdf;
      border-radius: 20px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;

      &::before {
        position: absolute;
        content: '';
        left: -1px;
        top: 0;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }
  }
}
