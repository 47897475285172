.course-subscribe {
  margin-bottom: 20px;
  padding: 50px 30px 0;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: $white;
  text-align: center;

  @include mobile {
    padding: 40px 20px 0;
    transform: translateY(-40px);
  }

  &__success {
    color: $primary;
    padding-bottom: 30px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input {
    &__error {
      text-align: center;
    }
  }

  .button {
    font-weight: 400;
    transform: translateY(20px);
  }
}
