.grid-item {
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  &__icon {
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
