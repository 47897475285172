@import 'colors';
@import 'media';

@import 'components/additional-info/AdditionalInfo.scss';
@import 'components/course-header/CourseHeader.scss';
@import 'components/course-subscribe/CourseSubscribe.scss';
@import 'components/lecturers/Lecturers.scss';
@import 'components/topic-list/TopicList.scss';
@import 'components/outcomes/Outcomes.scss';

.course-details {
  background-color: $white;

  &__content {
    padding-bottom: 160px;

    @include tablet {
      padding-bottom: 100px;
    }

    @include mobile {
      padding-top: 60px;
      padding-bottom: 100px;
      background-color: $light-white;
    }

    &--inactive {
      @include mobile {
        padding-top: 0;
      }
    }

    .u-container {
      display: flex;

      @include mobile {
        flex-direction: column;
      }
    }

    section {
      padding-top: 100px;
      padding-right: 120px;
      flex: 1;

      @include desktop {
        padding-right: 60px;
      }

      @include tablet {
        padding-top: 70px;
        padding-right: 50px;
      }

      @include mobile {
        padding-top: 60px;
        padding-right: 0;
        order: 2;
      }
    }

    aside {
      width: 380px;
      transform: translateY(-20px);

      @include tablet {
        width: 340px;
      }

      @include mobile {
        width: 100%;
        order: 1;
        transform: none;
        transform: translateY(-20px);
      }

      .instructors {
        margin-top: 40px;
      }
    }
  }

  &__get-started {
    .u-container {
      display: flex;
      flex-direction: column;
    }

    .button {
      align-self: center;
    }

    .evenzilla-link {
      align-self: center;
      padding: 0;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10px 20px;

        @include mobile {
          padding: 10px;
        }

        &.price {
          background-color: rgba(53, 33, 181, 0.1);
        }

        &:last-of-type {
          flex: 1;
        }
      }
    }
  }

  &__path-only {
    margin-bottom: 40px;
    padding: 50px 30px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: $white;
    text-align: center;

    @include mobile {
      transform: translateY(-40px);
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      color: $dark;
    }

    a {
      text-decoration: underline;
    }
  }
}
