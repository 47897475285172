@import 'colors';
@import 'media';

.course-calendar-mobile {
  position: relative;
  margin-top: 40px;
  flex-direction: column;

  .loading {
    @include tablet {
      height: calc(100% - 60px);
      width: calc(100% + 20px);
      left: -10px;
      bottom: 0;
    }

    @include mobile {
      height: calc(100% - 60px);
      width: calc(100% + 20px);
      left: -10px;
      bottom: 0;
    }
  }

  .react-calendar {
    margin-bottom: 20px;
    width: 100%;
    border: none;
    background-color: transparent;

    @include tablet {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      background: none !important;
    }

    &__navigation {
      margin-bottom: 20px;
      height: 60px;

      @include tablet {
        min-width: 50%;
      }

      &__label {
        pointer-events: none;
      }

      &__label__labelText {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.04;
        letter-spacing: normal;
        text-align: center;
        color: #172762;

        span {
          padding-top: 4px;
          display: block;
          font-size: 16px;
          font-weight: 300;
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 10px;
          height: 18px;

          path {
            fill: $dark;
          }
        }

        &:last-of-type {
          transform: rotate(180deg);
        }
      }
    }

    &__month-view__weekdays__weekday {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $dark;
      text-transform: none;

      abbr {
        text-decoration: none;
      }
    }

    &__month-view__days__day {
      position: relative;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0;
      color: $dark;
      font-weight: 300;

      &--neighboringMonth {
        color: #c2c8d2;

        .react-calendar__course-items {
          display: none;
        }
      }
    }

    &__course-items {
      position: absolute;
      bottom: 2px;
      padding-top: 3px;
      display: flex;
      opacity: 1;
      transition: all 0.1s ease-in-out;

      span {
        display: block;
        height: 7px;
        width: 7px;
        margin: 0 2px;
        border-radius: 50%;
        background-image: linear-gradient(to top, $secondary, #5138ee);
      }

      &--past {
        span {
          background-image: linear-gradient(to top, $grey, $grey);
        }
      }
    }

    &__tile {
      background-color: $white;

      &:after {
        position: absolute;
        top: 4px;
        z-index: 0;
        content: '';
        height: 34px;
        width: 34px;
        border-radius: 50%;
        opacity: 0;
        background-image: linear-gradient(to top, $secondary, #5138ee);
        transition: all 0.1s ease-in-out;
      }

      abbr {
        position: relative;
        z-index: 9;
        transition: all 0.1s ease-in-out;
      }

      &--active {
        color: $white;
        font-weight: 700;

        &:after {
          opacity: 1;
        }

        .react-calendar__course-items {
          opacity: 0;
        }
      }
    }

    &__viewContainer {
      padding: 20px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &__course {
    position: relative;
    margin: 10px 10px;
    padding: 20px;
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: $white;

    @include mobile {
      width: calc(100% - 20px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background-image: linear-gradient(to top, $secondary, #5138ee);
    }

    .date {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .u-type-label {
        position: relative;
        top: 0;
        margin-left: 10px;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        color: $dark;
      }
    }

    h4 {
      margin: 6px 0 10px;
      font-weight: 400;

      span {
        display: block;
        font-size: 12px;
        color: $grey;
      }
    }

    .type {
      font-size: 12px;
      font-weight: 700;
      color: #c2c8d2;
      margin-bottom: 10px;
    }

    a {
      align-self: flex-end;
      font-size: 14px;
      font-weight: 300;
      color: #c2c8d2;
      text-decoration: underline;
    }

    &--past {
      &::before {
        background-image: $grey;
        background-image: linear-gradient(to top, $grey, $grey);
      }

      .u-type-label {
        display: none;
      }

      .date span,
      h4 {
        color: $grey;
      }
    }
  }
}
